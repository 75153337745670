//jshint esversion:8

import { isBDay } from "./ext/openDate.js";
import setPage from "./ext/setPage.js";
import { late, soon, visited } from "./pages.js";
import { animate } from "./animation.js";


function setCookie(cname, cvalue, exdays = 1) {
  const d = new Date();
  d.setTime(d.getTime() + (1 * 24 * 60 * 60 * 1000));
  let expires = "expires="+d.toUTCString();
  document.cookie = cname + "=" + cvalue + ";" + expires + ";path=/";
}

function getCookie(cname) {
  let name = cname + "=";
  let ca = document.cookie.split(';');
  for(let i = 0; i < ca.length; i++) {
    let c = ca[i];
    while (c.charAt(0) == ' ') {
      c = c.substring(1);
    }
    if (c.indexOf(name) == 0) {
      return c.substring(name.length, c.length);
    }
  }
  return "";
}

function checkCookie(popopo) {
  let user = getCookie(popopo);
  return user != "";
}

/******************************************************* SETUP ************************************************************/

if (process.env.OPEN_DATE) {
  const status = isBDay();
  if (status === "IS_EARLY") setPage(soon);
  if (status === "IS_LATE") setPage(late);
  if (status === "ON_TIME") {
    if( checkCookie('visited') ) {
      if(  checkCookie('visited2') ) {
        setPage(visited);
      } else {
        setCookie('visited2', true);
        animate();
      }
    } else {
      animate();
      setCookie('visited', true);
    }
  }
} else {
  animate();
}
