export const soon = {
  title: "Come Back Later...",
  body: `<br />
    <h1>Hi, you come to early</h1>
    <br /><br /><br />
    <p>
        I know this page is very interesting for you, especially for your special day but.<br />
        You need to be patience until the time has come, right ?
    </p>`,
};

export const late = {
  title: "See you next time...",
  body: `<br />
    <h1>The party was over</h1>
    <br /><br /><br />
    <p>
        Yes, my gift for you is kinda simple, cheap, and weird ? &#128534<br>
        B-but. It's only for you. &#128150
    </p>
`,
};

export const visited = {
  title: "Astagfirullah!!!",
  body: `
    <style>h1{ font-size: 48px} h2{ font-size: 30px} p { font-size: 18px}</style>
    <br />
    <h1>Astagfirullah!!! Why are you visiting here again and again.</h1>
    <h2 style="color: #fb9f9f;">Do you want me to wish to many times in single day?</h2>
    <br /><br /><br />
    <p>Anyways! If, You liked this gift from me. Thank me on Instagram.</p>
`,
};